<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <p>
        Set batch untuk produk <strong>{{ obat.nama }}</strong>
      </p>
      <div class="formgrid grid">
        <div class="field col-12">
          <label
            :class="{
              'p-error': v$.batch.no.$invalid && submitted,
            }"
            >Batch</label
          >
          <InputText
            v-model="v$.batch.no.$model"
            :class="{
              'p-invalid': v$.batch.no.$invalid && submitted,
            }"
            class="w-full"
            autofocus
          />
          <small
            v-if="
              (v$.batch.no.$invalid && submitted) ||
              v$.batch.no.$pending.$response
            "
            class="p-error"
            >{{ v$.batch.no.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{
              'p-error': v$.batch.expired_at.$invalid && submitted,
            }"
            >Expired</label
          >
          <Calendar
            v-model="v$.batch.expired_at.$model"
            :class="{
              'p-invalid': v$.batch.expired_at.$invalid && submitted,
            }"
            dateFormat="dd/mm/yy"
            class="w-full"
            input-class="w-full"
          />
          <span v-if="v$.batch.expired_at.$error && submitted">
            <span
              v-for="(error, index) of v$.batch.expired_at.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small
            v-else-if="
              (v$.batch.expired_at.$invalid && submitted) ||
              v$.batch.expired_at.$pending.$response
            "
            class="p-error"
            >{{ v$.batch.expired_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-6">
          <label
            :class="{
              'p-error': v$.batch.quantity.$invalid && submitted,
            }"
            >Quantity</label
          >
          <InputNumber
            v-model="v$.batch.quantity.$model"
            :class="{
              'p-invalid': v$.batch.quantity.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
            @keyup.enter="handleSubmit(!v$.$invalid)"
          />
          <span v-if="v$.batch.quantity.$error && submitted">
            <span
              v-for="(error, index) of v$.batch.quantity.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </span>
          </span>
          <small
            v-else-if="
              (v$.batch.quantity.$invalid && submitted) ||
              v$.batch.quantity.$pending.$response
            "
            class="p-error"
            >{{ v$.batch.quantity.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="$emit('close')"
        />
        <Button
          :loading="isLoading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue } from '@vuelidate/validators'
import { formatDate } from '@/helpers'
import dayjs from 'dayjs'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    obat: {
      type: Object,
      default() {
        return {}
      },
    },
    listBatch: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      batch: {
        no: null,
        expired_at: null,
        quantity: 0,
      },
      isLoading: false,
      submitted: false,
    }
  },
  methods: {
    formatDate(value) {
      return formatDate(value)
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const form = {}

      form.pod_id = this.obat.pod_id
      form.batch_no = this.batch.no
      form.expired_at = this.batch.expired_at
        ? dayjs(this.batch.expired_at).format('YYYY-MM-DD')
        : null
      form.quantity = parseInt(this.batch.quantity)

      this.$emit('save', form)
    },
  },
  validations() {
    return {
      batch: {
        no: {
          required: helpers.withMessage('No batch harus diisi.', required),
        },
        expired_at: {
          required: helpers.withMessage('Expired harus diisi.', required),
        },
        quantity: {
          required: helpers.withMessage('Quantity harus diisi.', required),
          minValue: helpers.withMessage('Jumlah minimal 1.', minValue(1)),
        },
      },
    }
  },
}
</script>
