<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Barang Masuk</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Gudang / Barang masuk</span>
      </div>
    </div>
    <div class="card bg-white">
      <grid-barang-masuk
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :flagged="flagged"
        @request="onRequestData"
        @setrcvd="onConfirmStatusRcvd"
        @addbatch="onConfirmBatch"
        @deletebatch="onConfirmDeleteBatch"
        @print="onPrint"
      />
    </div>
    <Dialog
      header="Set status"
      v-model:visible="dialogRcvd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      closable
    >
      <div class="confirmation-content">
        <span
          >Status faktur pembelian
          <strong>{{ purchase_order.po_no }}</strong> akan diset menjadi RCVD.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          :loading="isLoadingRcvd"
          label="Set status"
          icon="pi pi-tag"
          class="p-button-text"
          @click="onSetStatusRcvd"
        />
      </template>
    </Dialog>
    <Dialog
      header="Tambah batch"
      v-model:visible="dialogBatch"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closable
    >
      <form-batch
        :obat="obat"
        :listBatch="list_batch"
        @close="onCloseBatch"
        @save="onSaveBatch"
      />
    </Dialog>
    <Dialog
      header="Hapus batch"
      v-model:visible="dialogHapusBatch"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div class="confirmation-content">
        <span
          >Batch <strong>{{ batch.no }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          :loading="isLoadingDeleteBatch"
          label="Hapus Batch"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteBatch"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import GudangMasukService from '@/services/GudangMasukService'
import BatchService from '@/services/BatchService'
import GridBarangMasuk from '@/components/gudang/GridBarangMasuk'
import FormBatch from '@/components/batch/FormBatchMasuk'
import errorHandler from '@/helpers/error-handler'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridBarangMasuk,
    FormBatch,
  },
  data() {
    return {
      batch: {},
      purchase_order: {},
      list_batch: [],
      obat: {
        id: null,
        nama: null,
      },
      dataService: null,
      dialogRcvd: false,
      dialogBatch: false,
      dialogHapusBatch: false,
      dialogViewer: false,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      isLoadingRcvd: false,
      isLoadingDeleteBatch: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'po_at',
        sortOrder: 1,
        filters: null,
      },
      flagged: [],
    }
  },
  created() {
    this.dataService = new GudangMasukService()
    this.batchService = new BatchService()
  },
  mounted() {
    this.loadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      let filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data faktur pembelian', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onConfirmStatusRcvd(data) {
      this.purchase_order = data
      this.dialogRcvd = true
    },
    onSetStatusRcvd() {
      const obat = this.purchase_order.obat
      let isValid = true

      obat.forEach((el) => {
        const totalQTYBatch = el.batch.reduce(function (total, item) {
          return total + item.quantity
        }, 0)

        if (!totalQTYBatch) {
          if (!this.flagged.includes(el.id)) {
            this.flagged.push(el.id)
          }
          isValid = false
        }
      })

      if (!isValid) {
        this.dialogRcvd = false
        this.$toast.add({
          severity: 'warn',
          summary: 'Data batch',
          detail: 'Data batch tidak valid, harap cek kembali.',
          life: 3000,
        })

        return true
      }
      this.isLoadingRcvd = true
      this.batchService
        .setRcvd(this.purchase_order.id)
        .then((res) => {
          if (res.data.message === 'OK') {
            this.dialogRcvd = false
            this.$toast.add({
              severity: 'success',
              summary: 'Faktur pembelian',
              detail: 'Status faktur pembelian berhasil diubah',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Set received', this)
        })
        .finally(() => {
          this.isLoadingRcvd = false
        })
    },
    onConfirmBatch(e) {
      this.obat.nama = e.nama_obat
      this.obat.id = e.obat_id
      this.obat.quantity = e.quantity
      this.obat.pod_id = e.id
      this.batchService.get(e.obat_id).then((res) => {
        this.list_batch = res.data.data
      })
      this.dialogBatch = true
    },
    onCloseBatch() {
      this.dialogBatch = false
    },
    onSaveBatch(form) {
      this.isLoading = true

      if (this.flagged.includes(form.pod_id)) {
        this.flagged = this.flagged.filter((el) => el !== form.pod_id)
      }

      this.batchService
        .addPurchase(form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Batch',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Batch', this)
        })
        .finally(() => {
          this.isLoading = false
        })
      this.dialogBatch = false
    },
    onConfirmDeleteBatch(e, pod_id) {
      this.batch.no = e.batch_no
      this.batch.batch_id = e.id
      this.batch.pod_id = pod_id
      this.dialogHapusBatch = true
    },
    onDeleteBatch() {
      this.isLoadingDeleteBatch = true
      this.batchService
        .deletePurchase(this.batch)
        .then((res) => {
          if (res.data.status === 200) {
            this.dialogHapusBatch = false
            this.$toast.add({
              severity: 'success',
              summary: 'Batch',
              detail: 'Data batch berhasil dihapus',
              life: 3000,
            })
            this.loadGridData()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Batch',
              detail: 'Data batch gagal dihapus',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Hapus batch', this)
        })
        .finally(() => {
          this.isLoadingDeleteBatch = false
        })
    },
    onPrint(data) {
      this.batchService
        .printMasuk(data.id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'barang-masuk.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print barang masuk', this)
        })
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
  },
}
</script>
