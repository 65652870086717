<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="son_no"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="son_no" header="No" style="min-width: 12rem" sortable>
        <template #body="{ data }">
          {{ data.son_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="son_between"
        field="son_at"
        header="Tanggal"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.son_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pabrik"
        sort-field="pabrik"
        filter-field="pabrik"
        header="Pabrik"
        style="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
        <template #body="{ data }">
          <Chip
            v-for="(value, key) in data.pabrik"
            :key="key"
            class="text-xs mr-2"
          >
            {{ value.nama }}
          </Chip>
        </template>
      </Column>
      <Column
        field="total_sku"
        header="Total SKU"
        type="numeric"
        style="min-width: 90px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatNumber(data.total_sku) }}
        </template>
      </Column>
      <Column
        field="selisih_qty"
        header="Selisih QTY"
        type="numeric"
        style="min-width: 90px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatNumber(data.selisih_qty) }}
        </template>
      </Column>
      <Column
        field="selisih_harga"
        header="Selisih Harga"
        type="numeric"
        style="min-width: 90px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.selisih_harga) }}
        </template>
      </Column>
      <Column style="flex-grow: 1; min-width: 100px; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-download"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            title="Download"
            @click="$emit('download', data.id)"
          />
          <!--<Button
            type="button"
            icon="pi pi-upload"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            title="Upload"
            @click="$emit('upload', data.id)"
          />-->
          <FileUpload
            mode="basic"
            accept=".xls"
            class="
              p-button
              p-component
              p-button-icon-only
              p-button-outlined
              p-button-secondary
              p-button-text
              mr-2
            "
            :maxFileSize="10000000"
            title="Upload"
            auto
            custom-upload
            @uploader="$emit('upload', data.id, $event)"
          />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            title="Edit"
            @click="$emit('edit', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            :value="slotProps.data.obat"
            :scrollable="true"
            class="p-datatable-sm"
            showGridlines
          >
            <Column
              field="pabrik.nama"
              header="Pabrik"
              style="min-width: 9rem"
            />
            <Column field="kode_obat" header="Kode" style="min-width: 5rem" />
            <Column field="nama_obat" header="Obat" style="min-width: 20rem" />
            <Column field="satuan" header="Satuan" />
            <Column
              field="harga"
              header="Harga"
              dataType="numeric"
              style="
                min-width: 6rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga) }}
              </template>
            </Column>
            <Column
              field="stock_akhir"
              header="Stok Akhir"
              style="
                min-width: 3rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatNumber(data.stock_akhir) }}
              </template>
            </Column>
            <Column
              field="stock_opname"
              header="Stok Opname"
              style="
                min-width: 3rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                <div
                  class="w-full p-1 text-right"
                  :class="data.stock_opname == null ? 'bg-orange-100' : ''"
                >
                  {{ formatNumber(data.stock_opname) }}
                </div>
              </template>
            </Column>
            <Column
              field="selisih"
              header="Selisih"
              style="
                min-width: 3rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatNumber(data.selisih) }}
              </template>
            </Column>
            <Column
              field="total"
              header="Total"
              dataType="numeric"
              style="
                min-width: 7rem;
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatNumber(data.total) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatNumber, formatDate, formatCurrency } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    CalendarFilter,
  },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        son_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        son_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pabrik: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
    formatCurrency(value) {
      return formatCurrency(value)
    },
  },
}
</script>
