<template>
  <div>
    <div class='mb-4 flex justify-content-between align-items-center'>
      <span class='pr-2 mr-2 font-bold text-2xl'>Stok Opname</span>
      <div>
        <i class='pi pi-home mr-2'></i>
        <span class='font-small'>/ Gudang / Stok Opname</span>
      </div>
    </div>
    <div class='card bg-white'>
      <div class='flex justify-content-end mb-2'>
        <Button
          icon='pi pi-plus'
          label='Stok Opname'
          class='mr-2'
          @click='add()'
        />
<!--        <Button
          :loading='isLoadingTemplate'
          icon='pi pi-download'
          label='Template'
          class='mr-2 p-button-success'
          @click='onTemplate()'
        />
        <Button
          :loading='isLoadingExport'
          icon='pi pi-file-excel'
          label='Export'
          class='p-button-outlined'
          @click='onExport()'
        />-->
      </div>
      <grid-stok-opname
        :items='items'
        :loading='isLoading'
        :total='totalRecords'
        :grid-options='options'
        @request='onRequestData'
        @edit='onEditData'
        @download='onDownloadTemplate'
        @upload='onUploadTemplate'
      />
    </div>
    <hotkey :shortcuts="['D']" @triggered='onTriggerHotkey' />
    <Dialog
      header='Buat stock opname'
      v-model:visible='dialogAdd'
      :style="{ width: '30vw' }"
      modal
    >
      <form-add-stock-opname
        :item='itemPabrik'
        :loading='isLoadingSave'
        @save='onSaveData'
      />
    </Dialog>
  </div>
</template>

<script>
import StockOpnameService from '@/services/StockOpnameService'
import GridStokOpname from '@/components/gudang/GridStokOpname'
import FormAddStockOpname from '@/components/gudang/FormAddStockOpname'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'

export default {
  components: {
    FormAddStockOpname,
    GridStokOpname,
    Hotkey
  },
  data() {
    return {
      listGolonganObat: null,
      dataService: null,
      itemPabrik: null,
      dialogAdd: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      isLoadingTemplate: false,
      items: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'son_at',
        sortOrder: 1,
        filters: null
      }
    }
  },
  created() {
    this.dataService = new StockOpnameService()
  },
  async mounted() {
    this.loadGridData()
  },
  methods: {
    add() {
      this.itemPabrik = {
        son_no: null,
        son_at: new Date(),
        pabrik: []
      }
      this.dialogAdd = true
    },
    onSaveData(e) {
      let pabrik = []
      let form = {}
      this.isLoadingSave = true
      e.pabrik.forEach((el) => {
        pabrik.push(el.id)
      })

      form.son_at = e.son_at ? new Date(e.son_at) : null
      form.pabrik = pabrik

      this.dataService
        .add(form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Stock Opname',
              detail: 'Data berhasil disimpan',
              life: 3000
            })
            this.dialogAdd = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Stock Opname', this)
        })
        .finally(() => {
          this.isLoadingSave = false
          this.loadGridData()
        })
    },
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Stok Opname', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.$router.push({ name: 'StokOpnameEdit', params: { id: data.id } })
    },
    onDownloadTemplate(id) {
      this.dataService.download(id).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'stock-opname-list.xls')
        document.body.appendChild(link)
        link.click()
      })
    },
    onUploadTemplate(id, e) {
      this.dataService
        .upload(id, e.files[0])
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Upload file',
              detail: 'Data stock opname berhasil diupload.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Upload data stock opname', this)
        })
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
      }
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.dataService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'export-stock-opname.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export data stock opname', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onTemplate() {
      this.isLoadingTemplate = true
      this.dataService
        .template()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'template-stock-opname.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Download template stock opname', this)
        })
        .finally(() => (this.isLoadingTemplate = false))
    },
  }
}
</script>
